import { Flatfile } from '@flatfile/api';

const lengthUoMOptions: Flatfile.EnumPropertyOption[] = [
  { value: 'Feet', label: 'Feet' },
  { value: 'Inches', label: 'Inches' },
  { value: 'Centimeters', label: 'Centimeters' },
  { value: 'Millimeters', label: 'Millimeters' },
];

const temperatureUoMOptions: Flatfile.EnumPropertyOption[] = [
  { value: 'DegreesCentigrade', label: 'Degrees Centigrade' },
  { value: 'DegreesFahrenheit', label: 'Degrees Fahrenheit' },
];

const accessOptions: Flatfile.EnumPropertyOption[] = [
  { value: 'Private', label: 'Private' },
  { value: 'Public', label: 'Public' },
];

export const productImportWorkbook: Readonly<Flatfile.CreateWorkbookConfig> = {
  name: 'Product Import Workbook',
  sheets: [
    {
      name: 'Products',
      fields: [
        {
          key: 'ProductName',
          type: 'string',
          label: 'Product Name',
          description: 'A name for the product.',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'ItemNumber',
          type: 'string',
          label: 'Item Number',
          description: "Your organization's unique ID for the product.",
          constraints: [
            {
              type: 'required',
            },
            {
              type: 'unique',
            },
          ],
        },
        {
          key: 'PackSize',
          type: 'string',
          label: 'Pack Size',
          description: "The number of items in the retail package. Typically '1 Each' for individually packaged items.",
        },
        {
          key: 'MPC',
          type: 'string',
          label: 'MPC',
          description: "The Manufacturer's Product Code for the product.",
        },
        {
          key: 'GTINUPC',
          type: 'string',
          label: 'GTINUPC',
          description: 'The Global Trade Item Number or Universal Product Code for the product.',
        },
        {
          key: 'GPCCode',
          type: 'string',
          label: 'GPCCode',
          description:
            'Global Product Classification (GPC) is part of the GS1 System of supply chain standards. The GPC Code (not description) should be provided.',
        },
        {
          key: 'BrandName',
          type: 'string',
          label: 'Brand Name',
          description: 'The brand name for the product.',
        },
        {
          key: 'VendorName',
          type: 'string',
          label: 'Vendor Name',
          description: 'The Company Name of the Vendor who supplies this product.',
        },
        {
          key: 'VendorID',
          type: 'string',
          label: 'Vendor ID',
          description: 'The unique Company Id your organization assigns to the Vendor.',
        },
        {
          key: 'MarketingMessage',
          type: 'string',
          label: 'Marketing Message',
          description:
            'Additional description of the product, typically used to record copy that will be used in marketing of the product.',
        },
        {
          key: 'Length',
          type: 'number',
          label: 'Length',
          description: 'The physical length of the product. This value is used in combination with the Length-UOM.',
        },
        {
          key: 'LengthUOM',
          type: 'enum',
          label: 'Length UOM',
          description:
            'The Unit Of Measure that the Length value is specified using. Valid options are: Feet, Inches, Centimeters, Millimeters',
          config: {
            options: lengthUoMOptions,
          },
        },
        {
          key: 'Width',
          type: 'number',
          label: 'Width',
          description: 'The physical Width of the product. This value is used in combination with the Width-UOM.',
        },
        {
          key: 'WidthUOM',
          type: 'enum',
          label: 'Width UOM',
          description:
            'The Unit Of Measure that the Width value is specified using. Valid options are: Feet, Inches, Centimeters, Millimeters',
          config: {
            options: lengthUoMOptions,
          },
        },
        {
          key: 'Height',
          type: 'number',
          label: 'Height',
          description: 'The physical Height of the product. This value is used in combination with the Height-UOM.',
        },
        {
          key: 'HeightUOM',
          type: 'enum',
          label: 'Height UOM',
          description:
            'The Unit Of Measure that the Height value is specified using. Valid options are: Feet, Inches, Centimeters, Millimeters',
          config: {
            options: lengthUoMOptions,
          },
        },
        {
          key: 'StorageTempMin',
          type: 'number',
          label: 'Storage Temp Min',
          description:
            'The Minimum Storage Temperature of the product. This value is used in combination with the Storage-Temp-Min-UOM.',
        },
        {
          key: 'StorageTempMinUOM',
          type: 'enum',
          label: 'Storage Temp Min UOM',
          description:
            'The Unit Of Measure that the Minimum Storage Temperature value is specified using. Valid options are: Degrees Centigrade, Degrees Fahrenheit',
          config: {
            options: temperatureUoMOptions,
          },
        },
        {
          key: 'StorageTempMax',
          type: 'number',
          label: 'Storage Temp Max',
          description:
            'The Maximum Storage Temperature of the product. This value is used in combination with the Storage-Temp-Max-UOM.',
        },
        {
          key: 'StorageTempMaxUOM',
          type: 'enum',
          label: 'Storage Temp Max UOM',
          description:
            'The Unit Of Measure that the Maximum Storage Temperature value is specified using. Valid options are: Degrees Centigrade, Degrees Fahrenheit',
          config: {
            options: temperatureUoMOptions,
          },
        },
        {
          key: 'Access',
          type: 'enum',
          label: 'Access',
          description:
            'Used to control the visibility of the product. Public products are available for view to other companies in the Network. Private products are not visible outside of your organization. the Valid options are: Public, Private',
          config: {
            options: accessOptions,
          },
        },
        {
          key: 'Department',
          type: 'string',
          label: 'Department',
          description:
            'A Department Name or other identifier that associates this product with a department in your organization.',
        },
        {
          key: 'ProductID',
          type: 'string',
          label: 'Product ID',
          description: 'Custom Field',
        },
        {
          key: 'CategoryCodes',
          type: 'string',
          label: 'Category Codes',
          description: 'Custom Field',
        },
      ],
    },
  ],
  actions: [
    {
      operation: 'importAction',
      mode: 'foreground',
      label: 'Import Products',
      description: 'Import data to Clover.',
      primary: true,
      constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
    },
  ],
};
